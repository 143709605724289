import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const nav = useNavigate();

  useEffect(() => {
    console.log("DOING STUFF!");
    nav("/welcome");
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center border-2 border-red-400"></div>
  );
}

export default App;
