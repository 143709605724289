import { CommentsPage } from "@/src/types/360report.types";

export type CommentsChartProps = {
  data: CommentsPage;
};

export const CommentsChart: React.FC<CommentsChartProps> = ({ data }) => {
  const { comments } = data;
  return (
    <>
      <div className="w-full bg-[#F4F0E5]  rounded-md border border-[#B1B1B1]">
        <div className="flex">
          <div className="py-2 pl-3 w-[15%] border-r border-[#B1B1B1] font-inter text-[18px] font-semibold">
            Reviewer
          </div>
          <div className="py-2 pl-3 font-inter text-[18px] font-semibold">
            Comments
          </div>
        </div>
      </div>

      <div className="w-full rounded-md border border-[#B1B1B1] bg-[#F4F0E5] mt-2">
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <div key={index} className="flex">
              <div
                className={`flex py-2 pl-3 w-[15%]  border-r border-[#B1B1B1] font-inter font-semibold items-center ${
                  index === comments.length - 1 ? "" : "border-b"
                }`}
              >
                {comment.reviewer}
              </div>
              <div
                className={`py-2 pl-3 w-[85%] bg-[#FDFCFA] border-[#B1B1B1] font-inter font-normal ${
                  index === 0 ? "rounded-t" : ""
                }
              ${index === comments.length - 1 ? "rounded-b" : "border-b"}`}
              >
                {comment.comment}
              </div>
            </div>
          ))
        ) : (
          <div className="flex border-[#B1B1B1] justify-center font-inter">
            There are no comments
          </div>
        )}
      </div>
    </>
  );
};
