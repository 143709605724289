import { ReportTypes } from "@src/types/report.types";
import {
  formatRating,
  friendlyNameToHexColor,
  scoreToFriendlyName,
} from "../report.utils";

export const SaTableBreakdownView: React.FC<{
  pillarBreakdowns: ReportTypes.Sa.PillarBreakdown[];
  slice?: [number, number];
}> = ({ pillarBreakdowns, slice }) => {
  const pbs = slice
    ? pillarBreakdowns.slice(slice[0], slice[1])
    : pillarBreakdowns;
  return (
    <div className="flex flex-col  px-8">
      {pbs
        .filter((pb) => pb.ratings.length > 0)
        .map((b, idx) => (
          <SaTablePillarSection
            idx={idx + 1 + (slice ? slice[0] : 0)}
            key={b.pillarName}
            pillarBreakdown={b}
          />
        ))}
    </div>
  );
};

const SaTablePillarSection: React.FC<{
  idx: number;
  pillarBreakdown: ReportTypes.Sa.PillarBreakdown;
}> = ({ idx, pillarBreakdown }) => {
  return (
    <div className="flex flex-col mt-4">
      <SaTablePillarTitle idx={idx} pb={pillarBreakdown} />
      <SaTablePillarRatingExplanation rating={parseInt(pillarBreakdown.avg)} />
      <PillarAnswersItem breakdown={pillarBreakdown} />
      <CommentSection comment={pillarBreakdown.comment} />
      <ToUplevelSection />
    </div>
  );
};

const SaTablePillarTitle: React.FC<{
  idx: number;
  pb: ReportTypes.Sa.PillarBreakdown;
}> = ({ idx, pb }) => {
  return (
    <div className="flex">
      <div
        className="flex-1 flex justify-between items-center px-4 bg-slate-200"
        style={{
          height: "60px",
        }}
      >
        <h3 className="flex-1 font-semibold text-slate-600 text-xl">{`${idx}. ${pb.pillarDesc}`}</h3>
        <span className="text-slate-500 font-semibold text-lg shrink grow-0">
          Average Rating:{" "}
        </span>
      </div>
      <div
        className="flex justify-center items-center h-full px-4 grow-0 shrink ml-1 font-bold text-2xl"
        style={{
          backgroundColor: friendlyNameToHexColor(
            scoreToFriendlyName(parseInt(pb.avg))
          ),
          width: "60px",
        }}
      >
        {formatRating(parseFloat(pb.avg))}
      </div>
    </div>
  );
};

const SaTablePillarRatingExplanation: React.FC<{ rating: number }> = ({
  rating,
}) => {
  return (
    <div className="flex bg-slate-200 py-2 px-5 w-full font-semibold text-slate-600 my-1">
      {ratingText(rating)}
    </div>
  );
};

const PillarAnswersItem: React.FC<{
  breakdown: ReportTypes.Sa.PillarBreakdown;
}> = ({ breakdown }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full">
        {breakdown.ratings.map((answer) => (
          <div key={answer.attribute} className="flex items-center">
            <PillarAnswerItem
              question={answer.attribute}
              rating={answer.rating}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const PillarAnswerItem: React.FC<{ question: string; rating: number }> = ({
  question,
  rating,
}) => {
  return (
    <div
      className="flex w-full bg-slate-100 items-center mb-1"
      style={{
        height: "34px",
      }}
    >
      <div className="flex-1">
        <h3 className="font-medium text-slate-600 ml-4">{question}</h3>
      </div>
      <div
        className="grow-0 shrink font-semibold flex justify-center items-center h-full"
        style={{
          backgroundColor: friendlyNameToHexColor(scoreToFriendlyName(rating)),
          width: "60px",
        }}
      >
        {rating}
      </div>
    </div>
  );
};

const CommentSection: React.FC<{ comment: string | null }> = ({ comment }) => {
  return (
    <div className="flex items-center w-full bg-sky-100 py-2 px-5 mt-1">
      <span className="text-sky-900 font-semibold">Comment:</span>
      <span className="text-sky-900 font-semibold">
        {comment ? comment : "none"}
      </span>
    </div>
  );
};

const ToUplevelSection: React.FC = () => {
  return (
    <div className="flex items-center w-full bg-sky-100 py-2 px-5 mt-1">
      <img
        src={"/images/icons/uplevel-icon.png"}
        alt="uplevel"
        className="w-4 h-4 mr-2"
      />
      <span className="text-sky-900 font-semibold">
        Visit our SLI Library for this Pillar
      </span>
    </div>
  );
};

function ratingText(rating: number) {
  switch (rating) {
    case 1:
      return "Poor";
    case 2:
      return "Fair";
    case 3:
      return "You are functional in this area, but a little improvement is recommended.";
    case 4:
      return "You are already good in this area and some improvement would make it into a strength.";
    case 5:
      return "You are very good in this area. Consider this a core strength. ";
    default:
      return "";
  }
}
