import { Backend } from "@src/backend/Backend";
import { O, RD } from "@src/prelude";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

type UsersResponse = {
  id: number;
  email: string;
  assessmentState:
    | { _tag: "incomplete" }
    | { _tag: "completed"; submittedAt: string };
};

export const UserAnswersPage: React.FC = () => {
  const [rdReports, setRdReports] = React.useState<
    RD.RemoteData<any, UsersResponse[]>
  >(RD.initial);

  useEffect(() => {
    Backend.getAdmin<UsersResponse[]>("users").then((r) => {
      console.log("RESPONSE: ", r);
      setRdReports(
        RD.success(
          r.filter(
            (response) => response.email !== null && response.email !== ""
          )
        )
      );
    });
  }, []);

  return (
    <div className="flex flex-col p-8">
      <h1 className="font-bold text-4xl mb-8">User Answers</h1>
      {pipe(
        rdReports,
        RD.toOption,
        O.fold(
          () => <div>Loading...</div>,
          (users) => <UsersTable users={users} />
        )
      )}
    </div>
  );
};
const UsersTable: React.FC<{ users: UsersResponse[] }> = ({ users }) => {
  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th>Email</th>
          <th>Date</th>
          <th>See Answers</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, idx) => (
          <tr className="border" key={idx}>
            <td className="border p-4">{user.email}</td>

            <td className="border p-4">
              <span>
                <span>
                  {user.assessmentState._tag === "completed" && (
                    <span>{`${user.assessmentState.submittedAt.substring(
                      0,
                      10
                    )} ${user.assessmentState.submittedAt.substring(
                      10
                    )}`}</span>
                  )}
                </span>
              </span>
            </td>
            <td className="px-4 border-4">
              <div className="flex justify-center m-4">
                <Link
                  to={"/admin/dashboard/user-answers/pre-assessment/" + user.id}
                  className="bg-slate-600 text-white rounded-md p-2 mr-2"
                >
                  pre-assessment
                </Link>
                <Link
                  to={
                    "/admin/dashboard/user-answers/post-assessment/" + user.id
                  }
                  className="bg-slate-600 text-white rounded-md p-2 ml-2"
                >
                  post-assessment
                </Link>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
