import { PrimaryButtonNext } from "@/src/components/buttons";
import { NavIntro } from "@/src/components/navs/nav-intro";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PeerAssessmentsCompletePage: React.FC = () => {
  const nav = useNavigate();
  return (
    <>
      <NavIntro />
      <div
        className=" relative flex justify-center h-screen "
        style={{
          backgroundImage: `url(${
            Math.round(Math.random()) === 1
              ? "/images/team-report.png"
              : "/images/RectangleWorking.png"
          })`,
          backgroundSize: "cover",
          minHeight: "443px",
        }}
      >
        <div className="flex justify-center item-center">
          <div className="flex">
            <div className="h-full flex flex-col justify-center items-center text-[#FFFFFF]  z-[99]">
              <div className="flex flex-col items-center max-w-3xl gap-3">
                <h5 className=" text-3xl font-bodonixt">
                  Assessment Completed
                </h5>
                <span className="text-center">
                  <p>
                    Thanks for completing the assessment! Please visit our
                    website SaturnLeadership.org for more information on how to
                    up level your leadership or explore coaching.
                  </p>
                </span>
                <PrimaryButtonNext
                  title="See results of my answers"
                  onClick={() => {
                    nav("/peer-onboard-complete/my-answers-report-pdf");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
