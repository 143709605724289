import { Backend } from "@/src/backend/Backend";
import { BackButton, PrimaryButton } from "@/src/components/buttons";
import {
  SurveyContainer,
  SurveyOneToFiveQuestion,
} from "@/src/components/survey/survey.components";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { RD } from "@/src/prelude";
import {
  OverallLeadershipSurveyForm,
  OverallLeadershipSurveyPillarScore,
} from "@/src/types/index.types";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export const PeerOverallLeadershipSurveyPage: React.FC = () => {
  const [rdSurvey, setRdSurvey] = React.useState<
    RD.RemoteData<any, OverallLeadershipSurveyForm>
  >(RD.initial);
  const [formComplete, setFormComplete] = React.useState<boolean>(false);
  const nav = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      const survey = await Backend.get<OverallLeadershipSurveyForm>(
        `/overall-leadership-survey`,
        "peer-onboard"
      );
      setRdSurvey(RD.success(survey));
    };

    fetchSurvey().then();
  }, []);

  useEffect(() => {
    if (RD.isSuccess(rdSurvey))
      setFormComplete(
        rdSurvey.value.overallPillarScores.every(
          (item: any) => item.score !== null
        )
      );
  }, [rdSurvey]);

  return pipe(
    rdSurvey,
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      () => <></>,
      (survey) => (
        <div className="w-full flex flex-col">
          <BackButton navToBack="/peer-onboard/preliminary-questions" />
          <div className="flex flex-col items-center">
            <div className="flex flex-col gap-10 pt-12 items-center p-12 w-full bg-[#FAF6F0]">
              <h1 className="text-4xl font-bodonixt">
                {`Saturn Leadership Index Peer Assessment`}
              </h1>
              <OverallLeadershipSurveySection
                selected={
                  survey.overallLeadership !== null
                    ? survey.overallLeadership.toString()
                    : null
                }
                onSelected={(n) => {
                  setRdSurvey((r) =>
                    pipe(
                      r,
                      RD.map((s) => ({ ...s, overallLeadership: n }))
                    )
                  );
                }}
              />
              <span className="font-gilroy-600">OVERALL LEADERSHIP</span>
              <SurveyContainer>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row"></div>
                  <div className="flex items-center px-4">
                    <div className="flex flex-row gap-16">
                      <div className="flex items-center justify-center w-5 text-sm text-center text-[#7D7D7D]">
                        Major Weakness
                      </div>
                      <div className="flex items-center justify-center w-5 text-sm text-center text-[#7D7D7D]">
                        Needs Improvement
                      </div>
                      <div className="flex items-center justify-center w-5 text-sm text-[#7D7D7D]">
                        Functional
                      </div>
                      <div className="flex items-center justify-center w-5 text-sm text-[#7D7D7D]">
                        Good
                      </div>
                      <div className="flex items-center justify-center w-5 text-sm text-[#7D7D7D]">
                        Strength
                      </div>
                    </div>
                  </div>
                </div>
                {survey.overallPillarScores.map(({ pillar, score }) => {
                  return (
                    <SurveyOneToFiveQuestion
                      key={pillar.slug}
                      question={`I ${pillar.description}`}
                      radioProps={{
                        selected: score !== null ? score.toString() : null,
                        onSelected: (n) => {
                          setRdSurvey((rd) => {
                            const overallPillarScores = (
                              r: OverallLeadershipSurveyForm
                            ): OverallLeadershipSurveyPillarScore[] =>
                              r.overallPillarScores.map((p) => {
                                if (p.pillar.slug === pillar.slug) {
                                  if (n === "") return { ...p, score: null };
                                  else return { ...p, score: parseInt(n!) };
                                } else {
                                  return p;
                                }
                              });

                            const z: RD.RemoteData<
                              any,
                              OverallLeadershipSurveyForm
                            > = pipe(
                              rd,
                              RD.map((r) => ({
                                ...r,
                                overallPillarScores: overallPillarScores(r),
                              }))
                            );

                            return z;
                          });
                        },
                        name: pillar.slug,
                        displayType: "WeaknessToStrength",
                      }}
                    />
                  );
                })}
              </SurveyContainer>
              <div className="py-8">
                <PrimaryButton
                  onClick={() => {
                    Backend.post<any>(
                      `/overall-leadership-survey/submission`,
                      survey,
                      "peer-onboard"
                    ).then((r) => {
                      console.log("R! ", r);
                      nav(`/peer-onboard/top-pillar`);
                    });
                  }}
                  disabled={!formComplete}
                  style={{
                    backgroundColor: "#222222",
                    color: "#FAF7F2",
                    padding: "16px 20px 16px 20px",
                    opacity: formComplete ? "" : "30%",
                  }}
                >
                  <div className="flex items-center">
                    Next Section
                    <HiChevronRight />
                  </div>
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      )
    )
  );
};

const OverallLeadershipSurveySection: React.FC<{
  selected: string | null;
  onSelected: (n: number) => void;
}> = () => {
  return (
    <SurveyContainer
      explanation={
        <span className="flex flex-col">
          <span className="font-gilroy-700 mb-6">
            Think about how you lead people. Please rate your overall leadership
            over the last 6 months in how you lead others and enable your team
            members to succeed:
          </span>
          <span className="font-gilroy-700 font-bold mb-6">
            Please rate yourself in the following leadership behaviors. The more
            honest you are with yourself, the more helpful this self-assessment
            will be.
          </span>
        </span>
      }
      children={undefined}
    />
  );
};
