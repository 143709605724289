import { PrimaryButton } from "@/src/components/buttons";
import React from "react";
import { useNavigate } from "react-router-dom";

export const MyWelcomeBackPage: React.FC = () => {
  const nav = useNavigate();

  return (
    <div className="items-center justify-center flex h-full">
      <div className="m-1 border border-solid border-[#22222226] rounded-md w-[477px] h-[504px]">
        <div className="mt-[14px] ml-[10px] mb-[14px] mr-[10px] pt-[10px] pb-[10px]">
          <div className="flex flex-col items-center">
            <div className="w-[112px] h-[73px]">
              <img
                src="/public/images/saturn-black-logo.png"
                alt="saturn-logo"
              />
            </div>
            <h2 className="font-bodonixt text-[40px] text-[#222222]">
              Welcome back
            </h2>
          </div>
          <div className="mt-[30px] text-center font-gilroy text-[16px] font-semibold text-[#222222] ">
            Access to your completed Self Assessments by clicking the button
            below.
          </div>
          <div className="mt-[30px] justify-center flex mb-[60px]">
            <PrimaryButton
              style={{
                backgroundColor: "#222222",
                color: "#FAF7F2",
                fontFamily: "gilroy",
                fontWeight: "600",
                fontSize: "16px",
                padding: "14px 30px 14px 30px",
              }}
              onClick={() => {
                nav("/my/dashboard/reports");
              }}
            >
              Login to your account
            </PrimaryButton>
          </div>
          <div className="border-t border-solid border-[#22222226]">
            <div className="font-gilroy font-bold text-[15px] text-center my-[15px]">
              <span className="text-[#4C4B4B]">
                This link will expire in 24 hours. If you have further questions
                please
              </span>
              <a href="" className="text-[#012F87]">
                {" "}
                contact support.
              </a>
            </div>
            <div className="text-center">
              <span>© 2023 Neuberg Gore</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
