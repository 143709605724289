import React from "react";
import QuestionLabel from "./question-label";

const NumberQuestion: React.FC<{
  question: string;
  answer: number;
  setAnswer: (newAnswer: number) => void;
}> = ({ question, answer, setAnswer }) => {
  return (
    <div className="flex flex-col items-start gap-8">
      <QuestionLabel question={question} />
      <div className="w-full py-4 ">
        <input
          type="number"
          value={answer}
          placeholder="Type your answer"
          id="answer"
          className="border-b border-[#C8B98C] font-gilroy text-[18px] bg-[#FAF6F0] text-start px-4 py-2 w-full"
          min={"0"}
          onChange={(e) => setAnswer(Number.parseInt(e.target.value))}
        />
      </div>
    </div>
  );
};

export default NumberQuestion;
