import type { BubbleDataPoint, ChartData, ChartOptions, Point } from "chart.js";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";

export type SaturnBarChartPdfProps = {
  labels: string[];
  data?: {
    majorWakness: number;
    needsImprovement: number;
    functional: number;
    good: number;
    strong: number;
  }[];
  width?: string;
  height?: string;
};

export const SaturnBarChart: React.FC<SaturnBarChartPdfProps> = ({
  labels,
  data,
  width,
  height,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  function splitTextByWords(text: string) {
    const words = text.split(" ");
    const result = [];

    if (!isNaN(parseInt(words[0].charAt(0)))) {
      result.push(words[0].charAt(0));
      words.shift();
    }

    for (let i = 0; i < words.length; i += 2) {
      const part = words.slice(i, i + 2).join(" ");
      result.push(part);
    }

    return result;
  }

  useEffect(() => {
    console.log("DATA: ", data);
  }, [data]);

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          boxHeight: 16,
          boxWidth: 16,
          font: {
            weight: "bold",
            family: "gilroy",
            size: width ? parseInt(width, 9) / 70 : 14,
            style: "normal",
          },
          padding: 30,
        },
      },
    },
    scales: {
      y: {
        border: {
          color: "transparent",
        },

        beginAtZero: true,
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}%`,
          font: {
            size: 12,
          },
        },
        grid: {
          color: (context) => {
            if (context.tick.value === 0) {
              return "#222222";
            }
            return "#E1E1E1";
          },
          tickColor: "transparent",
        },
      },
      x: {
        stacked: true,
        border: { color: "transparent" },
        grid: {
          color: "transparent",
          lineWidth: width ? parseInt(width, 10) / 100 : 14,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          align: "start",
          color: "#7D7D7D",
          font: {
            size: labels.length > 5 ? 9 : 14,
            weight: "bold",
            family: "gilroy",
          },
          labelOffset: -10,
        },
      },
    },
  };

  const chartLabels = labels.map((label) => {
    return splitTextByWords(label);
  });

  const chartData: ChartData<
    "bar",
    (number | Point | [number, number] | BubbleDataPoint | null)[],
    unknown
  > = {
    labels: chartLabels,
    datasets: [
      {
        label: "Strengths (4,5)",
        data:
          data?.length !== undefined
            ? data?.map((item) => item.strong + item.good)
            : [],
        backgroundColor: "#83BC75",
        categoryPercentage: 0.3,
      },
      {
        label: "Weakness and Functional (1-3)",
        data:
          data?.length !== undefined
            ? data?.map(
                (item) =>
                  -(item.majorWakness + item.functional + item.needsImprovement)
              )
            : [],
        backgroundColor: "#FF8E25",
        categoryPercentage: 0.3,
      },
    ],
  };

  return (
    <div
      style={{
        width: width ? width : "1000px",
        height: height ? height : "500px",
      }}
      className="flex flex-col items-center"
    >
      <Bar options={options} data={chartData} />
    </div>
  );
};
