import {
  SurveyContainer,
  SurveyOneToFiveQuestion,
} from "@/src/components/survey/survey.components";
import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Backend } from "../backend/Backend";
import { PrimaryButton } from "./buttons";

/*
 example QuestionWithAnswer:
  {"id":"adcca747-83b1-447a-a139-55c71f46637f","question":"I share my opinions and concerns, even if I lack certainty or my opinion is unpopular","pillar_name":"speak","created_at":"2023-04-24T16:00:11.880Z","updated_at":"2023-04-24T16:00:11.880Z","answer":null
*/

// function timeFromNow(date: Date): string {
//   // return time in seconds from now
//   const seconds = (new Date().getTime() - date.getTime()) / 1000

//   if (seconds < 60) {

//     return `${seconds.toFixed(0)} seconds`
//   } else {
//     return `${(seconds / 60).toFixed(0)} minutes`
//   }
// }

type QuestionWithAnswer = {
  id: string;
  question: string;
  pillar_name: string;
  answer: number | null;
};

export const SliAssessmentSurveyForm: React.FC<{
  questions: QuestionWithAnswer[];
  onSaved: () => void;
  onSave?: (selectedAnswers: { [key: string]: number | null }) => void;
  onSubmit?: (selectedAnswers: { [key: string]: number | null }) => void;
  navAfterButton?: string;
}> = ({ questions, onSaved, onSave, onSubmit }) => {
  const nav = useNavigate();
  const [formComplete, setFormComplete] = React.useState<boolean>(false);
  const [selectedAnswers, setSelectedAnswers] = React.useState<{
    [key: string]: number | null;
  }>(
    questions.reduce((acc, { id, answer }) => {
      acc[id] = answer;
      return acc;
    }, {} as { [key: string]: number | null })
  );

  useEffect(() => {
    if (selectedAnswers !== null) {
      setFormComplete(
        Object.values(selectedAnswers).every((value) => value !== null)
      );
    }
  }, [selectedAnswers]);

  const fillAll = () => {
    let updatedAnswers = { ...selectedAnswers };

    Object.keys(selectedAnswers).forEach((key) => {
      updatedAnswers = {
        ...updatedAnswers,
        [key]: Math.ceil(Math.random() * 5),
      };
    });

    setSelectedAnswers(updatedAnswers);

    if (onSave) {
      onSave(updatedAnswers);
    }
  };
  return (
    <div className="flex flex-col gap-8 px-8 items-center">
      <SurveyContainer>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row"></div>
          <div className="flex items-center px-4">
            <div className="flex flex-row gap-16 text-sm">
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Strongly Disagree
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Disagree
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Neutral
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Agree
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Strongly Agree
              </div>
            </div>
          </div>
        </div>
        {questions.map(({ question, id }) => {
          return (
            <SurveyOneToFiveQuestion
              question={question}
              radioProps={{
                selected:
                  selectedAnswers[id] !== null
                    ? selectedAnswers[id]!.toString()
                    : null,
                onSelected: (n) => {
                  setSelectedAnswers((r) => ({
                    ...r,
                    [id]: n ? parseInt(n) : null,
                  }));

                  if (onSave) {
                    onSave(selectedAnswers);
                  } else {
                    Backend.post(
                      `self-assessment/save`,
                      selectedAnswers,
                      "anon/"
                    ).then((_) => {
                      onSaved();
                    });
                  }
                },
                name: id,
                displayType: "Agreeableness",
              }}
            />
          );
        })}
      </SurveyContainer>
      <div className="self-center">
        <PrimaryButton
          onClick={() => {
            if (onSubmit) {
              onSubmit(selectedAnswers);
            } else {
              console.log("submit", selectedAnswers);
              Backend.post(
                `self-assessment/submission`,
                selectedAnswers,
                "anon/"
              ).then((r) => {
                console.log("SUBMITTED SELF ASSESSMENT: ", r);
              });
              nav(`/anon/final-questions`);
            }
          }}
          disabled={!formComplete}
          style={{
            backgroundColor: "#222222",
            color: "#FAF7F2",
            padding: "16px 20px 16px 20px",
            opacity: formComplete ? "" : "30%",
          }}
        >
          <div className="flex items-center">
            Next Section
            <HiChevronRight />
          </div>
        </PrimaryButton>
      </div>
      <button
        className="flex self-end w-[40px] h-[40px] bg-blue-100"
        onClick={fillAll}
      ></button>
    </div>
  );
};
