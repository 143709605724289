import App from "./App";
import "./index.css";
import { AdminRootPage } from "./routes/admin/admin-index.page";
import { AdminDashboardLayout } from "./routes/admin/dashboard/admin-dashboard.layout";
import { AdminPeerAssessmentsPage } from "./routes/admin/dashboard/peer-assessments.page";
import { UsersPage } from "./routes/admin/dashboard/user-reports.page";
import { AdminPSliaQuestionsPage } from "./routes/admin/slia-questions.page";
import { AdminPUserReportPage } from "./routes/admin/user/user-report-page";
import { AdminPUserReportSummaryPage } from "./routes/admin/user/user-report-summary.page";
import { AnonLayout } from "./routes/anon/anon.layout";
import { AssessmentsCompletePage } from "./routes/anon/assessments-complete.page";
import { PeerAssessmentsCompletePage } from "./routes/peer-onboard-complete/peer-assessments-complete.page";
import { ReportPdfPage } from "./routes/anon/report-pdf.page";
import { ReportPage } from "./routes/anon/report.page";
import { AnonReviewSelfReportPage } from "./routes/anon/review-self-report.page";
import { MyDashboardLayout } from "./routes/my/dashboard/layout";
import { MyDashboardReportPage } from "./routes/my/dashboard/report.page";
import { MyDashboardReports } from "./routes/my/dashboard/reports.page";
import { MyLayout } from "./routes/my/layout";
import { MySli360ReportPdfPage } from "./routes/my/sli-36-report-pdf.page";
import { MyWelcomeBackPage } from "./routes/my/welcome-back.page";
import { PeerOnboardFinalCommentsPage } from "./routes/peer-onboard/final-comments.page";
import { PeerOnboardIndexPage } from "./routes/peer-onboard/index.page";
import { PeerOverallLeadershipSurveyPage } from "./routes/peer-onboard/overall-leadership-survey.page";
import { PeerAssessmentPage } from "./routes/peer-onboard/peer-assessment.page";
import { PeerOnboardDashboardLayout } from "./routes/peer-onboard/peer-onboard-dashboard.layout";
import { PeerPreliminaryQuestionsPage } from "./routes/peer-onboard/peer-preliminary.page";
import { PeerTopPillarPage } from "./routes/peer-onboard/top-pillar.page";
import { SampleSli360CommentsPage } from "./routes/sample-sli-360/comments.page";
import { SampleSli360LeadershipSummaryPage } from "./routes/sample-sli-360/leadership-summary.page";
import { SampleSli360PillarSummaryPage } from "./routes/sample-sli-360/pillar-summary.page";
import { SampleSli360Layout } from "./routes/sample-sli-360/sample-sli-360.layout";
import { SampleSli360StrenghtsImprovementPage } from "./routes/sample-sli-360/strengths-improvement.page";
import { SelfOnboardFinalQuestionsPage } from "./routes/self-onboard/final-questions.page";
import { SelfOverallLeadershipSurveyPage } from "./routes/self-onboard/overall-leadership-survey.page";
import { SelfPreliminaryQuestionsPage } from "./routes/self-onboard/preliminary-questions.page";
import { SelfOnboardSelfAssessmentPage } from "./routes/self-onboard/self-assessment.page";
import { SelfOnboardLayout } from "./routes/self-onboard/self-onboard.layout";
import { StandaloneAssessmentPage } from "./routes/sli-session/standalone-assessment.page";
import { LetsGetStarted } from "./routes/welcome/lets-get-started";
import { LoginPage } from "./routes/welcome/login.page";
import { OnboardPage } from "./routes/welcome/onboard";
import { PeerOnboardMyAnswersReportPdfPage } from "./routes/peer-onboard-complete/my-answers-report-pdf.page";
import { SelfAssessmentStartPage } from "./routes/self-onboard/self-assessment-start.page";
import { PeerAssessmentStartPage } from "./routes/peer-onboard/peer-assessment-start.page";
import { UserAnswersPage } from "./routes/admin/dashboard/user-answers.page";
import { UserAnswersPreAssessmentPage } from "./routes/admin/dashboard/user-answers-pre-assessment.page";
import { UserAnswersPostAssessmentPage } from "./routes/admin/dashboard/user-answers-post-assessment.page";
import { LevelOfWeaknessesPage } from "./routes/self-onboard/level-of-weaknesses.page";

export const routes = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/admin",
    element: <AdminRootPage />,
  },
  {
    path: "/admin/users/:userId/report",
    element: <AdminPUserReportPage />,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboardLayout />,
    children: [
      {
        path: "users/:userId/report-summary",
        element: <AdminPUserReportSummaryPage />,
      },
      {
        path: "users/:userId/report",
        element: <AdminPUserReportSummaryPage />,
      },
      {
        path: "user-reports",
        element: <UsersPage />,
      },
      {
        path: "user-answers",
        element: <UserAnswersPage />,
      },
      {
        path: "user-answers/pre-assessment/:userId",
        element: <UserAnswersPreAssessmentPage />,
      },
      {
        path: "user-answers/post-assessment/:userId",
        element: <UserAnswersPostAssessmentPage />,
      },
      {
        path: "survey-questions",
        element: <AdminPSliaQuestionsPage />,
      },
      {
        path: "peer-assessments",
        element: <AdminPeerAssessmentsPage />,
      },
    ],
  },
  {
    path: "/anon/review-self-report",
    element: <AnonReviewSelfReportPage />,
  },
  {
    path: "/anon/login",
    element: <LoginPage />,
  },
  {
    path: "/sli-sessions/:sessionId",
    element: <AnonLayout />,
    children: [
      {
        path: "peer-assessment",
        element: <StandaloneAssessmentPage isPeerAssessment={true} />,
      },
      {
        path: "self-assessment",
        element: <StandaloneAssessmentPage isPeerAssessment={false} />,
      },
      {
        path: "report",
        element: <ReportPage />,
      },
    ],
  },
  {
    path: "/welcome",
    element: <AnonLayout />,
    children: [
      {
        path: "",
        element: <OnboardPage />,
      },
      {
        path: "lets-get-started",
        element: <LetsGetStarted />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "self-onboard",
    element: <SelfOnboardLayout />,
    children: [
      {
        path: "",
        element: <></>,
      },
      {
        path: "preliminary-questions",
        element: <SelfPreliminaryQuestionsPage />,
      },
      {
        path: "overall-leadership-survey",
        element: <SelfOverallLeadershipSurveyPage />,
      },
      {
        path: "self-assessment-start",
        element: <SelfAssessmentStartPage />,
      },
      {
        path: "self-assessment-by-pillar/:pillarNumber",
        children: [
          {
            path: "",
            element: <SelfOnboardSelfAssessmentPage />,
          },
        ],
      },
      {
        path: "self-assessment",
        element: <SelfOnboardSelfAssessmentPage />,
      },
      {
        path: "level-of-weaknesses",
        element: <LevelOfWeaknessesPage />,
      },
      {
        path: "final-questions",
        element: <SelfOnboardFinalQuestionsPage />,
      },
    ],
  },
  {
    path: "/peer-onboard",
    element: <PeerOnboardDashboardLayout />,
    children: [
      {
        path: "",
        element: <PeerOnboardIndexPage />,
      },
      {
        path: "preliminary-questions",
        element: <PeerPreliminaryQuestionsPage />,
      },
      {
        path: "overall-leadership-survey",
        element: <PeerOverallLeadershipSurveyPage />,
      },
      {
        path: "top-pillar",
        element: <PeerTopPillarPage />,
      },
      {
        path: "peer-assessment/:pillarNumber",
        element: <PeerAssessmentPage />,
      },
      {
        path: "peer-assessment-start",
        element: <PeerAssessmentStartPage />,
      },
      {
        path: "final-comments",
        element: <PeerOnboardFinalCommentsPage />,
      },
    ],
  },
  {
    path: "peer-onboard-complete",
    children: [
      {
        path: "congrats",
        element: <PeerAssessmentsCompletePage />,
      },
      {
        path: "my-answers-report-pdf",
        element: <PeerOnboardMyAnswersReportPdfPage />,
      },
    ],
  },
  {
    path: "/sample-sli-360",
    element: <SampleSli360Layout />,
    children: [
      {
        path: "pillar-summary-page",
        element: <SampleSli360PillarSummaryPage />,
      },
      {
        path: "leadership-summary-page",
        element: <SampleSli360LeadershipSummaryPage />,
      },
      {
        path: "strengths-improvement-page",
        element: <SampleSli360StrenghtsImprovementPage />,
      },
      {
        path: "comments-page",
        element: <SampleSli360CommentsPage />,
      },
    ],
  },
  {
    path: "/anon",
    element: <AnonLayout />,
    children: [
      {
        path: "self-assessment/report-complete",
        element: <AssessmentsCompletePage />,
      },
      {
        path: "self-assessment/report",
        element: <ReportPage />,
      },
      {
        path: "self-assessment/report-pdf/:reportType/:sessionId",
        element: <ReportPdfPage />,
      },
    ],
  },
  {
    path: "/my",
    element: <MyLayout />,
    children: [
      {
        path: "sli-360-report",
        element: <MySli360ReportPdfPage />,
      },
      {
        path: "welcome-back",
        element: <MyWelcomeBackPage />,
      },
      {
        path: "dashboard",
        element: <MyDashboardLayout />,
        children: [
          {
            path: "reports",
            element: <MyDashboardReports />,
          },
          {
            path: "reports/:sessionId",
            element: <MyDashboardReportPage />,
          },
        ],
      },
    ],
  },
];
