import { Backend } from "@/src/backend/Backend";
import { ConfirmDialog } from "@/src/components/dialogs/ConfirmDialog.component";
import { ErrorTracking } from "@/src/error-tracking";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { E, RD } from "@/src/prelude";
import { Sli360ReportTypes } from "@/src/types/360report.types";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PeerIcon from "@/public/images/peer-icon.png";
import { BuyDialog } from "@/src/components/dialogs/BuyDialog.component";
import { PeerReviewDialog } from "@/src/components/dialogs/PeerReviewDialog.component";

export type Session = {
  session_id: string;
  submitted_at: string;
  user_id: string;
  assessed_user_id: string;
};

const getMyReports = () =>
  Backend.getE<{
    sli360: Sli360ReportTypes.FullReport | null;
    selfReports: Session[];
    showPeerReviewButtons: boolean;
  }>(`/reports`, "my");

export const MyDashboardReports: React.FC = () => {
  const nav = useNavigate();
  const url = useLocation();
  const queryParams = new URLSearchParams(url.search);
  const mbUidParam = queryParams.get("uid");
  const [rdMySessions, setRdMySession] = useState<
    RD.RemoteData<
      any,
      {
        selfReports: Session[];
        sli360: Sli360ReportTypes.FullReport | null;
        showPeerReviewButtons: boolean;
      }
    >
  >(RD.initial);
  const [peerAssessmentDialog, setPeerAssessmentDialog] =
    useState<boolean>(false);

  useEffect(() => {
    setRdMySession(RD.pending);
    setTimeout(() => {
      getMyReports()
        .then((res) => {
          if (E.isRight(res)) {
            setRdMySession(RD.success(res.right));
          } else {
            switch (res.left.status) {
              case 504:
                setTimeout(() => {
                  getMyReports().then((res) => {
                    setRdMySession(RD.fromEither(res));
                  });
                }, 1000);
                return;
              case 406:
                setRdMySession(
                  RD.failure(
                    "You are not authorized. Redirecting you to the login page..."
                  )
                );
                setTimeout(() => {
                  nav("/welcome/login");
                }, 3000);
                return;
              default:
                ErrorTracking.captureException(res.left.body);
                setRdMySession(RD.failure(res.left.body));
            }
          }
        })
        .catch((_) => {
          setTimeout(() => {
            getMyReports().then((res) => {
              setRdMySession(RD.fromEither(res));
            });
          }, 1000);
        });
    }, 1500);
  }, []);

  return pipe(
    rdMySessions,
    RD.fold(
      () => <div></div>,
      () => (
        <div className="w-screen h-[60vh]">
          <FullContainerLoadingSpinner />
        </div>
      ),
      (e) => (
        <div>{`There was an issue. Please try reloading. ${JSON.stringify(
          e
        )}`}</div>
      ),
      (r) => (
        <div className="h-full">
          <div className="flex flex-row justify-between bg-[#F4F0E5] top-[20px] p-[20px]">
            <h6 className="font-gilroy font-semibold text-[38px] ">
              My reports
            </h6>
            {r.selfReports.length > 0 && (
              <div>
                {r.showPeerReviewButtons && (
                  <button
                    className="bg-[#E3B735] text-[#222222] font-gilroy font-semibold py-[14px] px-[16px] rounded text-[16px]"
                    onClick={() => {
                      setPeerAssessmentDialog(true);
                    }}
                  >
                    <div className="flex justify-center items-center">
                      Request Peer Review
                      <img
                        className="w-[18px] h-[15px] ml-1"
                        src={PeerIcon}
                        alt="peer-icon"
                      />
                    </div>
                  </button>
                )}
                {peerAssessmentDialog && (
                  <PeerReviewDialog
                    title="Request Peer Review"
                    description="Send a request of peer assesment writing your partner email"
                    userId={mbUidParam ? mbUidParam : ""}
                    onCancel={() => setPeerAssessmentDialog(false)}
                  />
                )}
                <button
                  className="bg-[#E3B735] text-[#222222] font-gilroy font-semibold ml-2 py-[14px] px-[16px] rounded text-[16px]"
                  onClick={() => {
                    // nav("/anon/self-assessment");
                    Backend.post<{ newSessionId: string }>(
                      "/session-actions/retake",
                      {},
                      "my"
                    ).then((r) => {
                      console.log("RESULT! ", r);
                      nav(`/self-onboard/preliminary-questions`);
                    });
                  }}
                >
                  Retake Self Assessment
                </button>
              </div>
            )}
          </div>

          {r.showPeerReviewButtons && (
            <div className="flex justify-center items-center p-[20px]">
              <WarningPeerLeft />
            </div>
          )}
          {r.selfReports.length > 0 ? (
            <div className="flex flex-col gap-16">
              <SelfReportsTable sessions={r.selfReports} />
              {r.sli360 && <Sli360ReportsTable sli360={[r.sli360]} />}
            </div>
          ) : (
            <NoSessions />
          )}
        </div>
      )
    )
  );
};

const Sli360ReportsTable: React.FC<{
  sli360: Sli360ReportTypes.FullReport[];
}> = ({ sli360 }) => {
  const nav = useNavigate();
  const [deleteSessionId, setDeleteSessionId] = useState<string>();

  const deleteSession = (sessionToDelete: string) => {
    Backend.deleteMy(`sessions/${sessionToDelete}`).then((r) => {
      console.log("R! ", r);
      setDeleteSessionId("");
      nav(0);
    });
  };

  return (
    <div className="flex gap-4 flex-wrap ">
      <table className="min-w-full bg-white rounded-lg ">
        <thead>
          <tr className="bg-[#F4F0E5] border-t border-b border-[#22222233] h-[60px] text-gilroy font-semibold text-[18px]">
            <th className="w-1/5 text-start pl-12">SLI Peer Reports Avg</th>
          </tr>
        </thead>
        <tbody className="">
          {sli360.map((_, idx) => {
            return (
              <tr
                key={idx}
                className="border bg-[#FAF6F0] hover:bg-[#D3D3D3] cursor-pointer text-center h-[60px] text-gilroy text-[18px]"
              >
                <td
                  className="border-t border-b border-[#22222233] text-start pl-12"
                  onClick={() => {
                    nav(`/my/sli-360-report`);
                  }}
                >
                  {`SLI Peer`}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {deleteSessionId && deleteSessionId?.length > 0 && (
        <ConfirmDialog
          title="DELETE"
          description="Are you sure that you want delete this session?"
          onAcept={() => deleteSession(deleteSessionId)}
          onCancel={() => setDeleteSessionId("")}
        />
      )}
    </div>
  );
};

const SelfReportsTable: React.FC<{
  sessions: Session[];
}> = ({ sessions }) => {
  const nav = useNavigate();
  const [deleteSessionId, setDeleteSessionId] = useState<string>();

  const deleteSession = (sessionToDelete: string) => {
    Backend.deleteMy(`sessions/${sessionToDelete}`).then((r) => {
      console.log("R! ", r);
      setDeleteSessionId("");
      nav(0);
    });
  };

  return (
    <div className="flex gap-4 flex-wrap ">
      {sessions ? (
        <table className="min-w-full bg-white rounded-lg ">
          <thead>
            <tr className="bg-[#F4F0E5] border-t border-b border-[#22222233] h-[60px] text-gilroy font-semibold text-[18px]">
              <th className="w-1/5 text-start pl-12">Self Reports</th>
              <th className="w-1/6 text-start">Submitted At</th>
              {/* <th className="w-1/6 text-start">Assignee</th>
              <th className="w-1/6 text-start">Reporter</th> */}
              {/* <th className=" pr-12"></th> */}
            </tr>
          </thead>
          <tbody className="">
            {sessions.map((s) => {
              return (
                <tr
                  key={s.session_id}
                  className="border bg-[#FAF6F0] hover:bg-[#D3D3D3] cursor-pointer text-center h-[60px] text-gilroy text-[18px]"
                >
                  <td
                    className="border-t border-b border-[#22222233] text-start pl-12"
                    onClick={() => {
                      nav(`/my/dashboard/reports/${s.session_id}`);
                    }}
                  >
                    {s.user_id === s.assessed_user_id
                      ? "Self-Assessment"
                      : "Peer-Assessment"}
                  </td>
                  <td
                    className="border-t border-b border-[#22222233] text-start"
                    onClick={() => {
                      nav(`/my/dashboard/reports/${s.session_id}`);
                    }}
                  >
                    {format(new Date(s.submitted_at), "dd MMM, yyyy HH:mm a")}
                  </td>
                  {/* <td
                    className="border-t border-b border-[#22222233] text-start"
                    onClick={() => {
                      nav(`/my/dashboard/reports/${s.session_id}`);
                    }}
                  >
                    {
                      sessionNames.find(
                        (userSession) =>
                          s.assessed_user_id === userSession.userId
                      )?.userName
                    }
                  </td> */}
                  {/* <td
                    className="border-t border-b border-[#22222233] text-start"
                    onClick={() => {
                      nav(`/my/dashboard/reports/${s.session_id}`);
                    }}
                  >
                    {
                      sessionNames.find(
                        (userSession) => s.user_id === userSession.userId
                      )?.userName
                    }
                  </td>
                  <td className="border-t border-b border-[#22222233] pr-12">
                    <div className="flex justify-end z-99">
                      <img
                        src="/images/icons/trash-icon.png"
                        alt="delete-icon"
                        className="cursor-pointer font-bold px-4 rounded w-12"
                        onClick={() => setDeleteSessionId(s.session_id)}
                      />
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-row justify-between w-full top-[20px] p-[20px]">
          <FullContainerLoadingSpinner />
        </div>
      )}

      {deleteSessionId && deleteSessionId?.length > 0 && (
        <ConfirmDialog
          title="DELETE"
          description="Are you sure that you want delete this session?"
          onAcept={() => deleteSession(deleteSessionId)}
          onCancel={() => setDeleteSessionId("")}
        />
      )}
    </div>
  );
};

const NoSessions: React.FC = () => {
  const nav = useNavigate();

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex-col items-center justify-center text-center">
        <div className="flex justify-center">
          <img
            src="/public/images/document-text.png"
            alt="document-text"
            className="w-[120px] h-[120px]"
          />
        </div>
        <div className="font-gilroy text-[#737373] text-[18px] p-4">
          You don’t have any past reports.
        </div>
        <button
          className="bg-[#E3B735] text-[#222222] font-gilroy font-semibold py-[14px] px-[16px] rounded text-[16px]"
          onClick={() => {
            Backend.post<{ newSessionId: string }>(
              "/session-actions/retake",
              {},
              "my"
            ).then((r) => {
              console.log("RESULT! ", r);
              nav(`/self-onboard/preliminary-questions`);
            });
          }}
        >
          Take Self assessment
        </button>
      </div>
    </div>
  );
};

const WarningPeerLeft: React.FC = () => {
  const [buyDialog, setBuyDialog] = useState<boolean>();

  return (
    <div className="flex w-full bg-[#00267D] py-[10px] text-[#FFFFFF] px-[30px] justify-between items-center rounded-xl">
      <div className="flex flex-col">
        <span className="font-inter font-bold text-[18px]">
          You have 3 Peer Assessment left for free.
        </span>
        <span className="font-inter text-[16px] font-[400]">
          Get a 360 view of your Leadership Performance.
        </span>
      </div>
      <div>
        <button
          onClick={() => setBuyDialog(true)}
          className="bg-[#5B76B0] py-[16px] px-[20px] rounded font-gilroy text-[18px] font-[600] mr-2"
        >
          Learn More
        </button>
        <button className="bg-[#FFFFFF] text-[#00267D] py-[16px] px-[20px] rounded font-gilroy text-[18px] font-[600] ml-2">
          Buy Now
        </button>
      </div>
      {buyDialog && (
        <BuyDialog
          title="Get a 360 view from of your Leadership Performance."
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          offerDescription="Get a 40% discount on your first order."
          onCancel={() => setBuyDialog(false)}
          onAcept={() => setBuyDialog(false)}
        />
      )}
    </div>
  );
};
