import { Backend } from "@/src/backend/Backend";
import { BackButton } from "@/src/components/buttons";
import { SliAssessmentSurveyForm } from "@/src/components/sli-assessment-form";
import { LoadingSpinner } from "@/src/loading";
import { O, RD } from "@/src/prelude";
import { SliaQuestion } from "@/src/types/index.types";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type SimplePillar = { id: string; number: number };

type GetPeerAssessmentResponse = {
  sessionQas: SliaQuestion[];
  nextPillar: SimplePillar | null;
};

export const PeerAssessmentPage: React.FC = () => {
  const nav = useNavigate();
  const pillarNumberParam = useParams().pillarNumber!;
  const [rdSessionQas, setRdSessionQas] = useState<
    RD.RemoteData<any, GetPeerAssessmentResponse>
  >(RD.initial);

  useEffect(() => {
    setRdSessionQas(RD.pending);
    Backend.get<{ sessionQas: SliaQuestion[]; nextPillar: SimplePillar }>(
      `/session-qas/?pillarNumber=${pillarNumberParam}`,
      "peer-onboard"
    )
      .then((r) => {
        setRdSessionQas(RD.success(r));
      })
      .catch((e) => {
        setRdSessionQas(RD.failure(e));
      });
  }, [pillarNumberParam]);

  const toBack = () => {
    if (pillarNumberParam !== "1") {
      return `/peer-onboard/peer-assessment/${
        Number.parseInt(pillarNumberParam) - 1
      }`;
    }

    return "/peer-onboard/peer-assessment-start";
  };

  const pillarName = () => {
    switch (pillarNumberParam) {
      case "1":
        return "Inspire with a Clear Vision & Roadmap";
      case "2":
        return "Listen with Openness & Curiosity";
      case "3":
        return "Speak with Transparency & Authenticity";
      case "4":
        return "Facilitate Healthy Debates & Meetings";
      case "5":
        return "Make Decisions Inclusively & Effectively";
      case "6":
        return "Delegate Effectively & Creates Accountable Teams";
      case "7":
        return "Develop People & Teams";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="min-h-screen w-full">
      {pipe(
        rdSessionQas,
        RD.toOption,
        O.fold(
          () => (
            <div className="flex justify-center items-center mt-20">
              <LoadingSpinner dim={40} />
            </div>
          ),
          (r) => (
            <>
              <div className="w-full flex flex-col">
                <BackButton navToBack={toBack()} />
                <div className="flex flex-col items-center">
                  <div className="flex flex-col gap-10 items-center  w-full px-12 bg-[#FAF6F0]">
                    <div className="text-center">
                      <h3 className="text-lg font-gilroy font-semibold text-[#727272]">
                        PILLAR {pillarNumberParam}
                      </h3>
                      <h1
                        className="m-4 text-2xl font-normal"
                        style={{ fontFamily: "Arial" }}
                      >
                        {pillarName()}
                      </h1>
                    </div>

                    <SliAssessmentSurveyForm
                      questions={r.sessionQas.map((qa) => ({
                        id: qa.id,
                        question: qa.peer_question,
                        pillar_name: qa.pillar_name,
                        answer: qa.answer,
                      }))}
                      onSave={(sas) => {
                        Backend.post(
                          "/peer-assessment-actions/save",
                          { selectedAnswers: sas },
                          "peer-onboard"
                        ).then(() => {});
                      }}
                      onSaved={() => {}}
                      onSubmit={() => {
                        if (r.nextPillar?.number) {
                          nav(
                            `/peer-onboard/peer-assessment/${r.nextPillar.number}`
                          );
                        } else {
                          nav(`/peer-onboard/final-comments`);
                          console.log("SUBMITTED SELF ASSESSMENT: ", r);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )
        )
      )}
    </div>
  );
};
