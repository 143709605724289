import { useNavigate } from "react-router-dom";
import { BackButton, PrimaryButton } from "@/src/components/buttons";
import { HiChevronRight } from "react-icons/hi";

export const PeerAssessmentStartPage: React.FC = () => {
  const nav = useNavigate();

  return (
    <>
      <div className="flex flex-col w-full">
        <BackButton navToBack="/peer-onboard/top-pillar" />
        <div className="flex flex-col p-8">
          <h1 className="self-center text-4xl font-bodonixt text-black mb-14 text-center">
            Leadership Behaviors
          </h1>
          <div
            className={`
            flex-1 flex flex-col  self-center items-start
            `}
          >
            <div className="font-gilroy text-lg bg-[#F4F0E5] font-semibold pl-14 pr-14 mb-[50px] p-[50px]">
              <div>
                Please rate your peer in the following 7 Leadership Pillars. If
                you "strongly agree," it's a leadership strength. And "strongly
                disagree" acknowledges a weakness.
              </div>
            </div>

            <div className="text-center mb-[60px] flex items-center justify-center w-full">
              <PrimaryButton
                type="submit"
                title="Next"
                style={{
                  backgroundColor: "#222222",
                  color: "#FAF7F2",
                  padding: "16px 20px 16px 20px",
                }}
                onClick={() => {
                  nav("/peer-onboard/peer-assessment/1");
                }}
              >
                <div className="flex items-center">
                  Continue
                  <HiChevronRight />
                </div>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
