import { ReportTypes, SliReport } from "@/src/types/report.types";
import { PageContainer, ReportCoverPage } from "./report-page.fcs";
import { OverallLeadershipScoreView } from "./sa/overall-leadership-score.view";
import { SaTableBreakdownView } from "./sa/sa-table-breakdown.view";

type FullReportPageProps = {
  report: SliReport;
};

export const FullReportPage: React.FC<FullReportPageProps> = ({ report }) => {
  return (
    <div
      className="flex flex-col overflow-auto"
      id="full-report"
      style={{
        width: "850px",
      }}
    >
      <div
        className="flex flex-col bg-white border "
        style={
          {
            // width: '800px'
          }
        }
      >
        <PageContainer pageNumber={1} withoutHeader={true}>
          <ReportCoverPage forUserEmail={report.users.email} />
        </PageContainer>
        <SaSummaryPage sa={report.sa} />
        <PageContainer pageNumber={3}>
          <SaTableBreakdownView
            pillarBreakdowns={report.sa.pillarBreakdown}
            slice={[0, 2]}
          />
        </PageContainer>
        <PageContainer pageNumber={4}>
          <SaTableBreakdownView
            pillarBreakdowns={report.sa.pillarBreakdown}
            slice={[2, 4]}
          />
        </PageContainer>
        <PageContainer pageNumber={4}>
          <SaTableBreakdownView
            pillarBreakdowns={report.sa.pillarBreakdown}
            slice={[4, 6]}
          />
        </PageContainer>
      </div>
    </div>
  );
};

export const SaSummaryPage: React.FC<{ sa: ReportTypes.Sa.SaReport }> = ({
  sa,
}) => {
  return (
    <PageContainer pageNumber={2}>
      <div
        className={`flex flex-1 flex-col w-full h-full items-center justify-center mb-8`}
        style={{ height: "300px" }}
      >
        <OverallLeadershipScoreView score={sa.overallLeadershipScore} />
        {/* <LeadershipSummaryView
          height={250}
          strengths={sa.leadershipSummary.strengths.toString()}
          weaknesses={sa.leadershipSummary.weaknesses.toString()}
          functional={sa.leadershipSummary.functional.toString()}
        /> */}
      </div>
    </PageContainer>
  );
};
