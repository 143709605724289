import { RadioButton } from "./radio-buttons";

export type OneToFiveDisplayType = "WeaknessToStrength" | "Agreeableness";

export type OneToFiveRadioButtonsProps = {
  selected: string | null;
  onSelected: (value: string | null) => void;
  name: string;
  displayType: OneToFiveDisplayType;
  withNa?: boolean;
};

export const OneToFiveRadioButtons: React.FC<OneToFiveRadioButtonsProps> = ({
  selected,
  name,
  onSelected: setSelected,
  withNa,
}) => {
  const MyRadioButton = ({ number }: { number: number }) => {
    return (
      <RadioButton
        isSelected={selected === number.toString()}
        name={name}
        value={number.toString()}
        onSelected={setSelected}
      />
    );
  };

  const NaRadioButton = () => {
    return (
      <RadioButton
        isSelected={selected === "NA"}
        name={name}
        value={"NA"}
        onSelected={setSelected}
      />
    );
  };

  return (
    <form className="flex gap-16 items-center">
      <MyRadioButton number={1} />
      <MyRadioButton number={2} />
      <MyRadioButton number={3} />
      <MyRadioButton number={4} />
      <MyRadioButton number={5} />
      {withNa && <NaRadioButton />}
    </form>
  );
};

type WeaknessToStrength =
  | "Major Weakness"
  | "Needs Improvement"
  | "Functional"
  | "Good"
  | "Strength";

export function numberToWeaknessToStrength(num: number): WeaknessToStrength {
  switch (num) {
    case 1:
      return "Major Weakness";
    case 2:
      return "Needs Improvement";
    case 3:
      return "Functional";
    case 4:
      return "Good";
    case 5:
      return "Strength";
    default:
      return "Functional";
  }
}

export function numberToAgreeableness(num: number): string {
  switch (num) {
    case 1:
      return "Strongly disagree";
    case 2:
      return "Disagree";
    case 3:
      return "Neutral";
    case 4:
      return "Agree";
    case 5:
      return "Strongly agree";
    default:
      return "Neutral";
  }
}
