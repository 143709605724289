import { Backend } from "@/src/backend/Backend";
import { BackButton, PrimaryButton } from "@/src/components/buttons";
import OptionQuestion from "@/src/components/questions/options-question";
import { LoadingSpinner } from "@/src/loading";
import { O, RD } from "@/src/prelude";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export type Pillar = {
  name: string;
  description: string;
};

type TopPillarsResponse = {
  allPillars: Pillar[];
};

export const PeerTopPillarPage: React.FC = () => {
  const nav = useNavigate();
  const [rdTopPillars, setRdTopPillars] = useState<
    RD.RemoteData<any, TopPillarsResponse>
  >(RD.initial);
  const [pillarAnswer, setPillarAnswer] = useState<string>();

  const onSubmit = (topPillars: TopPillarsResponse) => {
    const pillarData = topPillars.allPillars.find(
      (pillar) => pillar.description === pillarAnswer
    )?.name;
    console.log("SUBMIT: ", topPillars);
    Backend.post(
      "/top-pillar-answer",
      { topPillar: pillarData },
      "peer-onboard"
    ).then((r) => {
      nav("/peer-onboard/peer-assessment-start");
      console.log(r);
    });
  };

  const getPillarSelected = (topPillars: TopPillarsResponse) => {
    Backend.get<{ topPillarResponse: string }>(
      `/top-pillar-answer`,
      "peer-onboard"
    )
      .then((res) => {
        console.log("RESPONSE: ", res.topPillarResponse);
        setPillarAnswer(
          topPillars.allPillars.find(
            (pillar) => pillar.name === res.topPillarResponse
          )?.description
        );
      })
      .catch((e) => {
        console.log("ERROR OF START PEER ASSESSMENT! ", e);
      });
  };

  useEffect(() => {
    setRdTopPillars(RD.pending);
    Backend.get<TopPillarsResponse>(`/top-pillars`, "peer-onboard").then(
      (res) => {
        setRdTopPillars(RD.success(res));
        getPillarSelected(res);
      }
    );
  }, []);

  return (
    <div className="min-h-screen w-full">
      {pipe(
        rdTopPillars,
        RD.toOption,
        O.fold(
          () => (
            <div className="flex items-center justify-center mt-[60px]">
              <LoadingSpinner dim={40} />
            </div>
          ),
          (topPillars) => {
            return (
              <>
                <div className="w-full flex flex-col">
                  <BackButton navToBack="/peer-onboard/overall-leadership-survey" />
                  <div className="flex flex-col items-center">
                    <div className="flex flex-col w-[80%] px-12 bg-[#FAF6F0]">
                      <h1 className="self-center text-4xl font-bodonixt text-black m-14 text-center">
                        {"Top Pillar"}
                      </h1>
                      <div className="flex flex-col gap-8 mb-[60px]">
                        <OptionQuestion
                          setAnswer={(pillarSelected) => {
                            const slugAnswer = topPillars.allPillars.find(
                              (p) => p.description === pillarSelected
                            )?.description;
                            console.log("PILLAR SELECTER: ", slugAnswer);
                            setPillarAnswer(slugAnswer);
                          }}
                          options={topPillars.allPillars.map(
                            (pillar) => pillar.description
                          )}
                          answer={pillarAnswer ? pillarAnswer : ""}
                          question="Select the top pillar you think could be improved"
                        />
                        <div className="text-center mb-[60px]">
                          <PrimaryButton
                            title="Next"
                            disabled={!pillarAnswer}
                            style={{
                              backgroundColor: "#222222",
                              color: "#FAF7F2",
                              padding: "16px 20px 16px 20px",

                              opacity: pillarAnswer ? "" : "30%",
                            }}
                            onClick={() => {
                              onSubmit(topPillars);
                            }}
                          >
                            <div className="flex items-center">
                              Next
                              <HiChevronRight />
                            </div>
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }
        )
      )}
    </div>
  );
};
