import * as React from "react";
import { useNavigate } from "react-router-dom";

export const PrimaryButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (p) => {
  return (
    <button className="px-8 rounded-md grow-0 font-semibold" {...p}>
      {p.children}
    </button>
  );
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const BackButton: React.FC<{ navToBack: string }> = ({ navToBack }) => {
  const nav = useNavigate();
  return (
    <div className="py-5 px-2">
      <button
        className="flex items-center w-[100px] h-[50px] text-center pl-4 cursor-pointer"
        onClick={() => {
          nav(navToBack);
        }}
      >
        <div className="w-3 h-5 flex flex-row ">
          <img
            src="/images/icons/back-button.png"
            alt="back-icon"
            className=" cursor-pointer"
          />

          <div className="pl-5 font-gilroy font-bold self-center text-sm cursor-pointer">
            BACK
          </div>
        </div>
      </button>
    </div>
  );
};

export const PrimaryButtonNext: React.FC<ButtonProps> = ({
  title,
  onClick,
  type,
}) => {
  return (
    <PrimaryButton
      type={type}
      onClick={onClick}
      style={{
        backgroundColor: "#F5F2E8",
        color: "#222222",
        borderRadius: "6px",
        border: "1px",
        padding: "16px 20px 16px 20px",
      }}
    >
      <div className="flex items-center  gap-2">
        <div className="self-center font-gilroy font-weight-600 text-18">
          {title}
        </div>
      </div>
    </PrimaryButton>
  );
};
