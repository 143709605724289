import { Backend } from "@/src/backend/Backend";
import { NavIntro } from "@/src/components/navs/nav-intro";
import { ErrorTracking } from "@/src/error-tracking";
import { LoadingSpinner } from "@/src/loading";
import { E, RD } from "@/src/prelude";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { useForm } from "react-hook-form";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export const LetsGetStarted: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string; lastName: string; email: string }>();
  const nav = useNavigate();

  const [rdRegisterResult, setRdRegisterResult] = React.useState<
    RD.RemoteData<{ message: string }, any>
  >(RD.initial);

  const onSubmit = (data: any) => {
    setRdRegisterResult(RD.pending);
    Backend.postE<{ userId: string; nextUrl: string }, { message: string }>(
      "onboard/register",
      {
        email: data.email,
        name: data.name,
        lastName: data.lastName,
      }
    ).then((er) => {
      if (E.isRight(er)) {
        setRdRegisterResult(RD.success({ message: "Success" }));
        nav("/self-onboard/preliminary-questions");
      } else {
        const handleStatuses = [400, 401, 403, 406, 409];
        if (handleStatuses.includes(er.left.status)) {
          setRdRegisterResult(RD.failure({ message: er.left.body.message }));
        } else {
          ErrorTracking.captureResponseError({
            status: er.left.status,
            body: JSON.stringify(er.left.body),
          });
          setRdRegisterResult(RD.failure({ message: "Something went wrong" }));
        }
      }
    });
  };

  return (
    <>
      <NavIntro />
      <div className="flex-1 flex bg-[#FAF6F0]">
        <div className="flex-1 relative">
          <div className="absolute inset-0 flex flex-col  mt-16 items-center">
            <div className="flex flex-col items-center max-w-3xl gap-3">
              <h5 className=" text-5xl font-bodonixt  mb-4 text-center">
                {`Let's get started`}
              </h5>
              <form
                className="flex flex-col items-center gap-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <span className="text-[#222] font-gilroy text-lg font-semibold leading-6 text-center mt-8">
                  {`In order to save your results, please provide your email address.`}
                </span>
                <div className="flex flex-col w-full">
                  <div className="flex-1">
                    <input
                      type="email"
                      className="border-b border-[#C8B98C] bg-[#FAF6F0] py-4 px-1 text-start w-full"
                      placeholder="Type your email"
                      {...register("email", { required: true })}
                    />
                    <div className="h-2">
                      {errors.email && (
                        <span className="text-red-500">Email is required</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-between w-full">
                  <div className="flex-1">
                    <input
                      type="text"
                      className="border-b border-[#C8B98C] bg-[#FAF6F0] py-4 px-1 text-start w-full mr-1"
                      placeholder="First Name"
                      {...register("name", { required: true })}
                    />
                    <div className="h-2">
                      {errors.name && (
                        <span className="text-red-500">
                          First name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      className="border-b border-[#C8B98C] bg-[#FAF6F0] py-4 px-1 text-start w-full ml-1"
                      placeholder="Last Name"
                      {...register("lastName", { required: true })}
                    />
                    <div className="h-2">
                      {errors.lastName && (
                        <span className="text-red-500">
                          Last name is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex w-full">
                  <button
                    className="px-8 rounded-md grow-0 w-full"
                    type="submit"
                    title="Next"
                    style={{
                      backgroundColor: "#222222",
                      color: "#FAF7F2",
                      marginTop: "10px",
                      padding: "16px 20px 16px 20px",
                      justifyContent: "center",
                    }}
                  >
                    <div className="flex font-gilroy text-lg leading-6 items-center justify-center">
                      Next
                      <HiChevronRight />
                    </div>
                  </button>
                </div>
                {pipe(
                  rdRegisterResult,
                  RD.fold(
                    () => <></>,
                    () => <LoadingSpinner dim={30} />,
                    (e) => <div className="text-red-500">{e.message}</div>,
                    (_) => <></>
                  )
                )}
                <div>
                  <div
                    className="font-gilroy font-semibold text-lg  text-center cursor-pointer"
                    onClick={() => {
                      Backend.post<{ userId: string; nextUrl: string }>(
                        "registration/anonymous",
                        {}
                      )
                        .then(() => {
                          nav("/self-onboard/preliminary-questions");
                        })
                        .catch((_) => {
                          //TODO
                        });
                    }}
                  >
                    or continue without email
                  </div>
                  <div className="mt-2 font-gilroy font-medium text-md text-center cursor-pointer text-sm text-[#22222299] mb-10">
                    Proceeding without email will not save your results.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
