import { O } from "./prelude";

export module LStorage {
  export type Key = "jwt";

  export function get(key: Key): string | null {
    return localStorage.getItem(key);
  }

  export function getO(key: Key): O.Option<string> {
    return O.fromNullable(get(key));
  }

  export function set(key: Key, value: string) {
    return localStorage.setItem(key, value);
  }

  /* Helpers */

  export function removeJwt() {
    return localStorage.removeItem("jwt");
  }

  export function getJwt() {
    return get("jwt");
  }

  export function setJwt(jwt: string) {
    return set("jwt", jwt);
  }
}
