import { Backend } from "@/src/backend/Backend";
import { FullPeerReportPdf } from "@/src/components/report/full-peer-report-pdf";
import { O, RD } from "@/src/prelude";
import { Sli360ReportTypes } from "@/src/types/360report.types";
import { ReportTypes } from "@/src/types/report.types";
import { PDFViewer } from "@react-pdf/renderer";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";

export const MySli360ReportPdfPage: React.FC = () => {
  const [rdReport, setRdReport] = React.useState<
    RD.RemoteData<
      unknown,
      {
        sli360: Sli360ReportTypes.FullReport;
        lastSessionReport: ReportTypes.SliReport;
      }
    >
  >(RD.initial);

  useEffect(() => {
    setRdReport(RD.pending);
    Backend.get<{
      sli360: Sli360ReportTypes.FullReport;
      lastSessionReport: ReportTypes.SliReport;
    }>(`/sli-360-report`, "my").then((res) => {
      console.log("MOCK RES! ", res);
      setRdReport(RD.success(res));
      // Backend.get<any>(`/sessions/${params.sessionId}/report`, "my").then(
      //   (res) => {
      //     setRdReport(RD.success(res));
      //   }
      // );
    });
  }, []);

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <div>Loading...</div>,
      (report) => (
        <PDFViewer
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <FullPeerReportPdf
            report={report.lastSessionReport}
            mockReport={report.sli360}
          />
        </PDFViewer>
      )
    )
  );
};
