import { FormExplanationSection } from "../forms/form.components";
import {
  OneToFiveRadioButtons,
  OneToFiveRadioButtonsProps,
} from "../forms/one-to-five-radio-buttons";

export const SurveyContainer: React.FC<{
  children: React.ReactNode;
  explanation?: React.ReactNode;
}> = ({ children, explanation }) => {
  return (
    <div className="flex flex-col bg-[#FAF6F0] rounded-2xl w-full gap-4">
      {explanation && <FormExplanationSection explanation={explanation} />}
      {children && <div className=" pb-10 flex flex-col ">{children}</div>}
    </div>
  );
};

type SurveyOneToFiveQuestionProps = {
  question: string;
  radioProps: OneToFiveRadioButtonsProps;
  withNa?: boolean;
};

export const SurveyOneToFiveQuestion: React.FC<
  SurveyOneToFiveQuestionProps
> = ({ question, radioProps }) => {
  const upperFormatText = (inputText: string) => {
    const words = inputText.split(" ");

    words.forEach((word, index, array) => {
      if (word.includes(".")) {
        const nextWordIndex = index + 1;
        if (nextWordIndex < array.length) {
          array[nextWordIndex] =
            array[nextWordIndex].charAt(0).toUpperCase() +
            array[nextWordIndex].slice(1);
        }
      }
    });
    return words.join(" ");
  };

  return (
    <div className="flex justify-between border-b-[1px] border-[#F4F0E5] pb-5 pt-5 ">
      <h4 className="font-gilroy-600 font-regular first-uppercase">
        {upperFormatText(question)}
      </h4>
      <div className="flex items-center px-4">
        <OneToFiveRadioButtons {...radioProps} />
      </div>
    </div>
  );
};
