import { User } from "@/src/types/index.types";
import { Backend } from "@src/backend/Backend";
import { O, RD } from "@src/prelude";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FinalQuestion } from "../../self-onboard/final-questions.page";

export const UserAnswersPostAssessmentPage: React.FC = () => {
  const params = useParams<{ userId: string }>();
  const [user, setUser] = React.useState<User>();
  const [rdQuestions, setRdQuestions] = React.useState<
    RD.RemoteData<any, FinalQuestion[]>
  >(RD.initial);

  useEffect(() => {
    Backend.getAdmin<User>("users/" + params.userId).then((r) => {
      console.log("RESPONSE: ", r);
      setUser(r);
    });
    Backend.get<FinalQuestion[]>(
      `final-assessment/questions/?userId=${params.userId}`
    )
      .then((r) => {
        console.log("GOT PROGRESS: ", r);
        setRdQuestions(RD.success(r));
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div className="flex flex-col p-8">
      <div className="flex">
        <Link
          to="/admin/dashboard/user-answers"
          className="font-bold text-4xl mb-8 hover:underline"
        >{`User Answers`}</Link>
        <h1 className="font-bold text-4xl mb-8 ml-2">
          {" > Post-assessments"}
        </h1>
      </div>
      {pipe(
        rdQuestions,
        RD.toOption,
        O.fold(
          () => <div>Loading...</div>,

          (questions) => (
            <>
              <h1 className="font-bold text-4xl mb-8">
                {`${user?.first_name} ${user?.last_name}`}
              </h1>
              <AnswersTable questions={questions} />
            </>
          )
        )
      )}
    </div>
  );
};
const AnswersTable: React.FC<{ questions: FinalQuestion[] }> = ({
  questions,
}) => {
  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th>Question</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        {questions.map((question, idx) => (
          <tr className="border" key={idx}>
            <td className="border p-4">{question.question.questionText}</td>

            <td className="border p-4">
              {question.answer ? question.answer : "No answer"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
