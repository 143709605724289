import { PrimaryButtonNext } from "@/src/components/buttons";
import { NavIntro } from "@/src/components/navs/nav-intro";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AssessmentsCompletePage: React.FC = () => {
  const [isShowingGenerateReport, setIsShowingGenerateReport] =
    React.useState(true);
  const nav = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsShowingGenerateReport(false);
    }, 3500);
  }, []);

  return (
    <>
      <NavIntro />
      <div
        className=" relative flex justify-center h-screen "
        style={{
          backgroundImage: `url(${
            Math.round(Math.random()) === 1
              ? "/images/team-report.png"
              : "/images/RectangleWorking.png"
          })`,
          backgroundSize: "cover",
          minHeight: "443px",
        }}
      >
        <div className="flex justify-center item-center">
          <div className="flex">
            {isShowingGenerateReport ? (
              <GeneratingReportView />
            ) : (
              <div className="h-full flex flex-col justify-center items-center text-[#FFFFFF]  z-[99]">
                <div className="flex flex-col items-center max-w-3xl gap-3">
                  <h6 className="font-gilroy">RESULTS</h6>
                  <h5 className=" text-3xl font-bodonixt">
                    Assessment Completed
                  </h5>
                  <span className="text-center">
                    <p>
                      Thanks for completing the assessment. Please visit our
                      website SaturnLeadership.org for more information on how
                      to up level your leadership or explore coaching.
                    </p>
                  </span>
                  <PrimaryButtonNext
                    title="See Results on Dashboard"
                    onClick={() => {
                      nav("/my/dashboard/reports");
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const GeneratingReportView: React.FC = () => {
  return (
    <div className="absolute inset-0 flex flex-col justify-center items-center text-[#FFFFFF] p-4">
      <div className="flex flex-col items-center max-w-3xl gap-3">
        <h1 className="font-bold text-2xl font-bodonixt">
          Generating your report...
        </h1>
      </div>
    </div>
  );
};
