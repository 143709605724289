import { Font, Text, View } from "@react-pdf/renderer";
import interBold from "@src/fonts/Inter-Bold.ttf";
import interSemibold from "@src/fonts/Inter-SemiBold.ttf";
import inter from "@src/fonts/Inter-Regular.ttf";
import gilroyBold from "@src/fonts/Gilroy-Bold.ttf";
import gilroy from "@src/fonts/Gilroy-Regular.ttf";
import gilroySemiBold from "@src/fonts/Gilroy-SemiBold.ttf";
import React from "react";
import { CommentsPage } from "@/src/types/360report.types";

Font.register({
  family: "Gilroy",
  src: gilroy,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Semi-Bold",
  src: gilroySemiBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Bold",
  src: gilroyBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Inter",
  src: interBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Inter",
  src: interSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Inter",
  src: inter,
  fontStyle: "normal",
  fontWeight: "normal",
});

export type CommentsChartPdfProps = {
  data: CommentsPage;
  width?: string;
  height?: string;
};

export const CommentsChartPdf: React.FC<CommentsChartPdfProps> = ({ data }) => {
  const { comments } = data;
  return (
    <View style={{ paddingHorizontal: "15px" }}>
      <View
        style={{
          width: "100%",
          backgroundColor: "#F4F0E5",
          borderRadius: "4px",
          borderWidth: 1,
          borderColor: "#B1B1B1",
        }}
      >
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text
            style={{
              paddingVertical: "4px",
              paddingLeft: "6px",
              width: "15%",
              borderRightWidth: 1,
              borderColor: "#B1B1B1",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "semibold",
            }}
          >
            Reviewer
          </Text>
          <Text
            style={{
              paddingVertical: "4px",
              paddingLeft: "6px",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "semibold",
            }}
          >
            Comments
          </Text>
        </View>
      </View>

      <View
        style={{
          width: "100%",
          borderRadius: "4px",
          borderWidth: 1,
          borderColor: "#B1B1B1",
          backgroundColor: "#F4F0E5",
          marginTop: "10px",
        }}
      >
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <View key={index} style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingVertical: "4px",
                  paddingLeft: "6px",
                  width: "15%",
                  borderRightWidth: 1,
                  borderColor: "#B1B1B1",
                  fontFamily: "Inter",
                  fontWeight: "semibold",
                  alignItems: "center",
                  borderBottomWidth: index === comments.length - 1 ? "" : 1,
                }}
              >
                <Text>{comment.reviewer}</Text>
              </View>
              <Text
                style={{
                  paddingVertical: "4px",
                  paddingLeft: "6px",
                  width: "85%",
                  backgroundColor: "#FDFCFA",
                  borderColor: "#B1B1B1",
                  fontFamily: "Inter",
                  fontWeight: "normal",
                  borderTopLeftRadius: index === 0 ? "4px" : "",
                  borderTopRightRadius: index === 0 ? "4px" : "",
                  borderBottomRightRadius:
                    index === comments.length - 1 ? "4px" : "",
                  borderBottomLeftRadius:
                    index === comments.length - 1 ? "4px" : "",
                  borderBottomWidth: index === comments.length - 1 ? "" : 1,
                }}
              >
                {comment.comment}
              </Text>
            </View>
          ))
        ) : (
          <Text
            style={{
              display: "flex",
              flexDirection: "row",
              borderColor: "#B1B1B1",
              justifyContent: "center",
              fontFamily: "Inter",
            }}
          >
            There are no comments
          </Text>
        )}
      </View>
    </View>
  );
};
