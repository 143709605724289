import React from "react";

export type ScoreOnReportProps = {
  title: string;
  score: number;
};

const ScoreOnReport: React.FC<ScoreOnReportProps> = ({ title, score }) => {
  const ScoreColor = () => {
    switch (true) {
      case score > 4:
        return "#83BC75";
      case score <= 4 && score > 3:
        return "#D2E7CE";
      case score <= 3 && score > 2:
        return "#FEB165";
      case score <= 2 && score > 1:
        return "#FF8E25";
      case score < 1:
        return "#E64925";
    }
  };
  const scales = [
    { number: 5, title: "Strong", color: "#83BC75" },
    { number: 4, title: "Good", color: "#D2E7CE" },
    { number: 3, title: "Functional", color: "#FEB165" },
    { number: 2, title: "Needs Improvement", color: "#FF8E25" },
    { number: 1, title: "Major Weakness", color: "#E64925" },
  ];
  return (
    <div
      style={{
        gap: "15px",
        alignItems: "center",
        width: "100%",
        borderColor: "#E1E1E1",
        borderWidth: 1,
        padding: 10,
        maxHeight: "380px",
      }}
    >
      <div
        style={{
          fontFamily: "inter",
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "center",
          color: "#222222",
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "400px",
          height: "200px",
        }}
      >
        <div
          style={{
            margin: "20px",
            display: "flex",
            backgroundColor: ScoreColor(),
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "inter",
              fontSize: "50px",
              fontWeight: "bold",
              color: "#222222",
              textAlign: "center",
            }}
          >
            {score}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 0px 20px 20px",

            width: "50%",
          }}
        >
          <div
            style={{
              fontFamily: "inter",
              fontWeight: "bold",
              fontSize: "15px",
              color: "#222222",
            }}
          >
            Scale
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {scales.map((scale, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                key={index}
              >
                <div
                  style={{
                    backgroundColor: scale.color,
                    display: "flex",
                    width: "12px",
                    height: "12px",
                    alignSelf: "center",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    height: "26px",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "12px",
                      color: "#222222",
                      paddingLeft: "2px",
                      alignSelf: "center",
                    }}
                  >
                    {scale.number} - {scale.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreOnReport;
