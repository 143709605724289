import { Backend } from "@/src/backend/Backend";
import { SaturnBarChart } from "@/src/components/charts/saturn-bar-chart/opportunities-saturn-bar-chart";
import { SaturnPieChart } from "@/src/components/charts/saturn-pie-chart/saturn-pie-chart";
import ScoreOnReport from "@/src/components/charts/score-on-report/score-on-report";
import {
  PillarAvgTotalRatings,
  PillarTypesPercentage,
  StrengthsOrWeaknessRatings,
} from "@/src/components/report/report.utils";
import { O, RD } from "@/src/prelude";
import { ReportTypes } from "@/src/types/report.types";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FullContainerLoadingSpinner } from "@/src/loading";

export const MyDashboardReportPage: React.FC = () => {
  const params = useParams<{ sessionId: string }>();
  const nav = useNavigate();
  const { sessionId } = params;
  const [rdReport, setRdReport] = useState<
    RD.RemoteData<any, ReportTypes.SliReport>
  >(RD.initial);

  const FormatDate = (dateString: string) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedDate = `${day} ${month}, ${year} - ${hours}:${minutes}`;

    return formattedDate;
  };

  useEffect(() => {
    setRdReport(RD.pending);
    Backend.get<any>(`/sessions/${sessionId}/self`, "my").then((res) => {
      setRdReport(RD.success(res));
    });
  }, []);

  useEffect(() => {
    console.log("REPORT: ", rdReport);
  }, [rdReport]);

  const leadershipSummaryData = [
    {
      color: "#83BC75",
      title: "Strengths",
    },
    {
      color: "#FEB165",
      title: "Functional",
    },
    {
      color: "#E64925",
      title: "Weaknesses",
    },
  ];

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (report) => (
        <div className="">
          <div className="flex flex-row justify-between bg-[#F4F0E5] top-[20px] p-[20px]">
            <div className="flex flex-wor">
              <h6
                className="font-gilroy font-bold text-[20px] flex items-center cursor-pointer hover:underline"
                onClick={() => nav("/my/dashboard/reports")}
              >
                My reports
              </h6>
              <h6 className="font-gilroy font-bold text-[20px] flex items-center ml-2">
                /{" "}
                {report.users.email === report.users.assessedEmail
                  ? "Self Assessment"
                  : "Peer Assessment"}{" "}
                {FormatDate(report.date.sessionSubmited.toString())}
              </h6>
            </div>

            <div className="flex flex-row gap-4">
              <button
                className="bg-[#E3B735] text-[#222222] font-gilroy font-semibold py-[14px] px-[16px] h-full rounded text-[16px]"
                onClick={() => {
                  if (report.users.email !== report.users.assessedEmail) {
                    window.open(`/my/peer-sessions/${sessionId}/report-pdf`);
                  } else {
                    window.open(
                      `/anon/self-assessment/report-pdf/${
                        report.users.email === report.users.assessedEmail
                          ? "self-assessment"
                          : "peer-assessment"
                      }/${sessionId}`,
                      "_blank"
                    );
                  }
                }}
              >
                Download PDF Details
              </button>
              <div></div>
            </div>
          </div>
          <div className="flex gap-4 p-10 flex-row bg-[#F8F8F8] w-[73%] max-w-[750px] bg-[#FFFFFF]">
            <div>
              <ScoreOnReport
                title="Overall Leadership Score"
                score={PillarAvgTotalRatings(report.sa.pillarBreakdown)}
              />
            </div>
            <div className="border border-[#E1E1E1]-100 p-1">
              <div>
                <div
                  style={{
                    fontFamily: "gilroy",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#222222",
                    textAlign: "center",
                  }}
                >
                  Leadership Summary
                </div>
              </div>
              <div className="w-[200px]">
                <SaturnPieChart
                  data={[
                    {
                      color: leadershipSummaryData[0].color,
                      title: leadershipSummaryData[0].title,
                      value: PillarTypesPercentage(
                        report.sa.leadershipSummary
                      )[0],
                    },
                    {
                      color: leadershipSummaryData[1].color,
                      title: leadershipSummaryData[1].title,
                      value: PillarTypesPercentage(
                        report.sa.leadershipSummary
                      )[1],
                    },
                    {
                      color: leadershipSummaryData[2].color,
                      title: leadershipSummaryData[2].title,
                      value: PillarTypesPercentage(
                        report.sa.leadershipSummary
                      )[2],
                    },
                  ]}
                />
              </div>
              <div className="flex flex-row justify-between">
                {leadershipSummaryData.map((item, index) => (
                  <div
                    key={index + item.title}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "0px 5px 0px 5px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: item.color,
                        width: "13px",
                        height: "13px",
                        paddingTop: "3px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "13px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "gilroy",
                          fontSize: "10px",
                          color: "#222222",
                          paddingLeft: "2px",
                          alignSelf: "center",
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="p-10 max-w-[750px] w-[73%] bg-[#FFFFFF]">
            <div className="border border-[#E1E1E1]-100 p-1  flex-column max-h-[400px]">
              <div
                style={{
                  fontFamily: "gilroy",
                  fontWeight: "bold",
                  fontSize: "20px",
                  color: "#222222",
                  textAlign: "center",
                }}
              >
                Strengths & Improvement Opportunities
              </div>
              <div className="w-[70%]">
                <SaturnBarChart
                  labels={report.sa.pillarBreakdown.map((pillar) => {
                    return pillar.pillarNumber + " " + pillar.pillarDesc;
                  })}
                  data={StrengthsOrWeaknessRatings(report)}
                  width="650px"
                  height="325px"
                />
              </div>
            </div>
          </div>
        </div>
      )
    )
  );
};
