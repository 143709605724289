import { NavIntro } from "@/src/components/navs/nav-intro";
import { Outlet } from "react-router-dom";

export const MyDashboardLayout: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-col w-screen h-screen">
      <NavIntro />

      <div className="">
        <Outlet />
      </div>
    </div>
  );
};
