import { Backend } from "@/src/backend/Backend";
import { O, RD } from "@/src/prelude";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SliaQuestion } from "@src/types/index.types";
import { useNavigate } from "react-router-dom";
import { pipe } from "fp-ts/lib/function";

type SQResponse = {
  questions: (SliaQuestion & { pillarNumber: number; orderInPillar: number })[];
  allPillars: { name: string; id: string }[];
};

export const AdminPSliaQuestionsPage: React.FC<Record<string, never>> = () => {
  const [rdQuestions, setRdQuestions] = React.useState<
    RD.RemoteData<any, SQResponse>
  >(RD.initial);
  const nav = useNavigate();

  useEffect(() => {
    Backend.getAdmin<SQResponse>("slia-questions").then((r) => {
      setRdQuestions(RD.success(r));
    });
  }, []);

  return pipe(
    rdQuestions,
    RD.toOption,
    O.fold(
      () => <div>Loading...</div>,
      (r) => (
        <div className="flex flex-col p-12">
          <table className="border-2 mb-8">
            <thead>
              <tr>
                <th>Question</th>
                <th>Pillar</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {r.questions.map((q, idx) => (
                <tr key={idx}>
                  <td className="border">{`${q.pillarNumber}.${q.orderInPillar}  ${q.question}`}</td>
                  <td className="border">{q.pillar_name}</td>
                  <button
                    className="bg-red-200 text-white rounded-md p-2"
                    onClick={() => {
                      Backend.deleteAdmin(`slia-questions/${q.id}`).then(
                        (r) => {
                          console.log("R! ", r);
                          nav(0);
                        }
                      );
                    }}
                  >
                    Delete
                  </button>
                </tr>
              ))}
            </tbody>
          </table>
          <CreateQuestionForm allPillars={r.allPillars} />
        </div>
      )
    )
  );
};

type CreateQuestionFormData = {
  question: string;
  pillarId: string;
};

const CreateQuestionForm: React.FC<{
  allPillars: { name: string; id: string }[];
}> = ({ allPillars }) => {
  const { register, handleSubmit } = useForm<{
    question: string;
    pillarId: string;
    orderInPillar: number;
  }>();

  const onSubmit = handleSubmit((data: CreateQuestionFormData) => {
    Backend.postAdmin("slia-questions", data).then((r) => {
      console.log("R! ", r);
      window.location.reload();
    });
  });

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col gap-4 max-w-lg border border-black mt-16"
    >
      <h1 className="font-bold">Add a new question to survey. Be careful!</h1>
      <label className="text-slate-800 font-medium">Question</label>
      <input
        type="text"
        className="border border-slate-400 rounded-md p-2"
        {...register("question")}
      />
      <label className="text-slate-800 font-medium">Pillar Name</label>
      <select
        className="border border-slate-400 rounded-md p-2"
        {...register("pillarId")}
      >
        {allPillars.map((p, idx) => (
          <option key={idx} value={p.id}>
            {p.name}
          </option>
        ))}
      </select>
      <label className="text-slate-800 font-medium">Order in pillar</label>
      <input
        type="number"
        className="border border-slate-400 rounded-md p-2"
        {...register("orderInPillar")}
      />
      <input
        type="submit"
        className="bg-slate-800 text-white rounded-md p-2"
        value="Create Question"
      />
    </form>
  );

  // return (
  //   <form
  //     // onSubmit={onSubmit}
  //     className="flex flex-col gap-4"
  //   >
  //   div className="flex flex-col gap-2">
  //       <label className="text-slate-800 font-medium">Question</label>
  //       <input
  //         type="text"
  //         className="border border-slate-400 rounded-md p-2"
  //         {...register('question'), { required: true }}
  //       />
  //       <label className="text-slate-800 font-medium">Pillar Name</label>
  //       <input
  //         type="text"
  //         className="border border-slate-400 rounded-md p-2"
  //         {...register('pillarName'), { required: true }}
  //       />
  //       <input
  //         type="submit"
  //         className="bg-slate-800 text-white rounded-md p-2"
  //         value="Create Question"
  //       />
  //     </div>

  //   </form>
  // )
};
